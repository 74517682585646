/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'
import URI from 'urijs'
import { useTranslation } from 'react-i18next'
import { useCart, useOrders } from 'react-omnitech-api'
import { useLink } from '../../hook'
import { isBrowser, poll } from '../../helpers'
import CheckoutPaymentProcessingView from './checkout-payment-processing-view'

function CheckoutPaymentProcessingController({ location }) {
  // prepare hook
  const { navigate } = useLink()
  const { t } = useTranslation()
  const { createCart, resetCart } = useCart()
  const { fetchOrderByUuid, updateOrderToDecline } = useOrders()

  // local variable
  const seoTitle = t('screens.checkoutPaymentProcessing.seo.title')

  const {
    cartId,
    uuid,
  } = useMemo(() => {
    let search = {}
    if (isBrowser()) {
      const url = new URI(location.href)
      search = url.search(true)
    }
    return search
  }, [location])

  async function fetchOrder() {
    try {
      const includes = [
        'payment_requests',
      ]
      const apiParams = {
        uuid,
        includes,
        schema_version: '2019-08-15',
        price_template: 'mobile_v1',
        arrayFormat: 'bracket',
      }
      const { order } = await fetchOrderByUuid(apiParams)
      const { paymentState, state } = order

      if (state === 'reserved') {
        return order
      }
      switch (paymentState) {
        case 'payment_pending':
          return { ...order, retry: true }
        case 'payment_paid':
          return order
        default:
          throw order
      }
    } catch (error) {
      console.warn('handleGetOrders error: ', error)
      throw error
    }
  }

  /**
   * when payment is failed,
   * - decline order and back to checkout
   * - otherwise, back to cart page and start again
   */
  async function onError(error) {
    const errorMessage = _.get(error, 'message')
    const passState = {
      replace: true,
    }
    try {
      await updateOrderToDecline({
        uuid,
        byType: 'by_uuid',
        action: 'decline',
      })
      const urlObject = new URI('/')
      urlObject.addSearch({
        id: cartId,
        errorMessage,
      })
      navigate(`/checkout/${urlObject.search()}`, passState)
    } catch (err) {
      navigate('/cart/', passState)
    }
  }

  async function onResetCart(order) {
    try {
      const includes = []
      const options = {
        params: {
          includes,
        },
      }

      // reset cart and previous order uuid for decline order back from 3rd party gateway
      resetCart()

      const data = await createCart(options)
      console.log('[Project] onCreateCart success')
    } catch (error) {
      console.warn('[Project] onCreateCart error: ', error)
      // const generalError = _.get(error, 'generalError', {})
      // alert.show(generalError.message)
    } finally {
      // no matter reset and create cart success or fail, both will continue
      // to checkout complete page
      navigate(
        `/checkout/completed/?uuid=${uuid}&referenceNumber=${order.referenceNumber}`,
        {
          replace: true,
        },
      )
    }
  }

  // poll order and check status for order complete
  useEffect(() => {
    if (!_.isEmpty(uuid)) {
      poll(
        () => { return fetchOrder() },
        60000,
        1000,
        (resp) => { return !resp.retry },
      )
        .then((order) => {
          // payment success, reset and create new cart and go to checkout complete page
          onResetCart(order)
        })
        .catch((err) => {
          console.warn(err)
          const paymentRequests = _.get(err, 'paymentRequests')
          if (!_.isEmpty(paymentRequests)) {
            const { errorMessages = [] } = _.last(paymentRequests)
            err = { ...err, message: _.join(errorMessages, '\n') }
          }
          // TODO: handle error
          onError(err)
        })
    }
  }, [uuid])

  const viewProps = {
    seoTitle,
  }

  return (
    <CheckoutPaymentProcessingView {...viewProps} />
  )
}

export default CheckoutPaymentProcessingController
